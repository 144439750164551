export const audioEnabled = useLocalStorage<boolean>('audio', true, {
  serializer: {
    read(raw) {
      return raw === 'true'
    },
    write(value) {
      return value.toString()
    },
  },
})
